import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Preview from 'components/Preview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "this-step-takes-our-static-components-and-populates-them-with-data-from-the-github-graphql-api--loading-states-and-all-well-be-displaying-carbon-repository-information-in-a-data-table"
    }}>{`This step takes our static components and populates them with data from the GitHub GraphQL API – loading states and all. We’ll be displaying Carbon repository information in a data table.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fork, clone and branch</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install dependencies</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Create access token</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Connect to Apollo</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Fetch data</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Populate data table</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add loading</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add pagination</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Submit pull request</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "preview"
    }}>{`Preview`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.github.com/v4/"
      }}>{`GitHub GraphQL API`}</a>{` is very well documented, and even though the focus of this tutorial isn’t learning and using GraphQL, it’s a great opportunity to fetch Carbon-related data for this Carbon tutorial.`}</p>
    <p>{`To do so, we’ll be using Apollo Client, the front-end component of the `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/intro/platform"
      }}>{`Apollo Platform`}</a>{`. Apollo provides several open source tools for using GraphQL throughout your application’s stack. Apollo Client is a sophisticated GraphQL client that manages data and state in an application.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://vue-step-4--carbon-tutorial-vue.netlify.com"
      }}>{`preview`}</a>{` of what you will build (see repositories page):`}</p>
    <Preview height="400" title="Carbon Tutorial Step 3" src="https://vue-step-4--carbon-tutorial-vue.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" className="bx--iframe bx--iframe--border" mdxType="Preview" />
    <h2 {...{
      "id": "fork-clone-and-branch"
    }}>{`Fork, clone and branch`}</h2>
    <p>{`This tutorial has an accompanying GitHub repository called `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
      }}>{`carbon-tutorial-vue`}</a>{` that we’ll use as a starting point for each step. If you haven’t forked and cloned that repository yet, and haven’t added the upstream remote, go ahead and do so by following the `}<a parentName="p" {...{
        "href": "/tutorial/vue/step-2#fork-clone--branch"
      }}>{`step 2 instructions`}</a>{`.`}</p>
    <h3 {...{
      "id": "branch"
    }}>{`Branch`}</h3>
    <p>{`With your repository all set up, let’s check out the branch for this tutorial step’s starting point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git fetch upstream
$ git checkout -b vue-step-3 upstream/vue-step-3
`}</code></pre>
    <h3 {...{
      "id": "build-and-start-app"
    }}>{`Build and start app`}</h3>
    <p>{`Install the app’s dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn
`}</code></pre>
    <p>{`Then, start the app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn serve
`}</code></pre>
    <p>{`You should see something similar to where the `}<a parentName="p" {...{
        "href": "/tutorial/vue/step-2"
      }}>{`previous step`}</a>{` left off. Stop your app with `}<inlineCode parentName="p">{`CTRL-C`}</inlineCode>{` and let’s get everything installed.`}</p>
    <h2 {...{
      "id": "install-dependencies"
    }}>{`Install dependencies`}</h2>
    <p>{`We’ll shortcut this using the Vue CLI, if you’d like more information head over to `}<a parentName="p" {...{
        "href": "https://vue-apollo.netlify.com/guide/installation.html#vue-cli-plugin"
      }}>{`Vue Apollo Installation`}</a>{` for details.`}</p>
    <p><em parentName="p">{`Note: If you have not yet installed the Vue CLI, you will need to `}<a parentName="em" {...{
          "href": "https://cli.vuejs.org/"
        }}>{`install the Vue CLI`}</a>{` before running the Vue Apollo Installation.`}</em></p>
    <p>{`Install the following`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`apollo-boost`}</inlineCode>{` - package containing everything you need to set up Apollo Client`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`graphql`}</inlineCode>{` - parses your GraphQL queries`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`vue-apollo`}</inlineCode>{` - Apollo integration for Vue`}</li>
    </ul>
    <p>{`Using the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ vue add apollo
`}</code></pre>
    <p>{`At the following prompts answer ‘No’ to each of the questions.`}</p>
    <ul>
      <li parentName="ul">{`Add example code? No`}</li>
      <li parentName="ul">{`Add a GraphQL API Server? No`}</li>
      <li parentName="ul">{`Configure Apollo Engine? No`}</li>
    </ul>
    <h2 {...{
      "id": "create-access-token"
    }}>{`Create access token`}</h2>
    <p>{`You’ll need a personal access token from your GitHub account in order to make requests to the GitHub API. Check out `}<a parentName="p" {...{
        "href": "https://help.github.com/en/articles/creating-a-personal-access-token-for-the-command-line"
      }}>{`this guide`}</a>{` to see how to get one.`}</p>
    <p>{`When you get to the scope/permissions step, you can leave them all unchecked. We don’t need any special permissions, we just need access to the public API.`}</p>
    <p>{`Once you have your token, we need to put it in a place where `}<inlineCode parentName="p">{`create-vue-app`}</inlineCode>{` can use it. When your application is being built and developed, create-vue-app will parse environmental variables in any file that starts with `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and make them available under `}<inlineCode parentName="p">{`process.env.MY_VARIABLE`}</inlineCode>{`.`}</p>
    <p>{`One caveat is that we need to start our variables with `}<inlineCode parentName="p">{`VUE_APP_`}</inlineCode>{`. You can read more about environmental variables in `}<a parentName="p" {...{
        "href": "https://cli.vuejs.org/guide/mode-and-env.html#environment-variables"
      }}>{`create-vue-app’s guide`}</a>{`.`}</p>
    <p><em parentName="p">{`Note: If you already have a valid GitHub Personal Access Token, you can use that token here.`}</em></p>
    <p>{`Since we don’t want to commit this file to Git, we can put it in `}<inlineCode parentName="p">{`.env.local`}</inlineCode>{` which is in our `}<inlineCode parentName="p">{`.gitignore`}</inlineCode>{` list. Your file should just have a single line like this one, where the `}<inlineCode parentName="p">{`x`}</inlineCode>{`s are replaced with your unique token.`}</p>
    <h5 {...{
      "id": "envlocal"
    }}>{`.env.local`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`VUE_APP_GITHUB_PERSONAL_ACCESS_TOKEN=xxxxxx
`}</code></pre>
    <p>{`Go ahead and start your app with `}<inlineCode parentName="p">{`yarn serve`}</inlineCode>{`, or, if your app is running, you’ll need to restart it to get access to this token.`}</p>
    <h2 {...{
      "id": "connect-to-apollo"
    }}>{`Connect to Apollo`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`vue-apollo`}</inlineCode>{` plugin has made a number of changes to our project.`}</p>
    <p>{`If you open `}<inlineCode parentName="p">{`src/main.js`}</inlineCode>{` you will see that the CLI has updated this file with the following:.`}</p>
    <h5 {...{
      "id": "srcmainjs"
    }}>{`src/main.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { createProvider } from 'vue-apollo';

new Vue({
  router,
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app');
`}</code></pre>
    <p>{`This is loading from a file the CLI created for you `}<inlineCode parentName="p">{`src/vue-apollo.js`}</inlineCode>{` which we need to update to target the github api.`}</p>
    <h4 {...{
      "id": "srcvue-apollojs"
    }}>{`src/vue-apollo.js`}</h4>
    <p>{`Update the following values:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Use our access token
const AUTH_TOKEN = process.env.VUE_APP_GITHUB_PERSONAL_ACCESS_TOKEN;

// Target github api
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || 'https://api.github.com/graphql';
`}</code></pre>
    <p>{`Update only the `}<inlineCode parentName="p">{`wsEndpoint`}</inlineCode>{` and `}<inlineCode parentName="p">{`getAuth`}</inlineCode>{` properties of the `}<inlineCode parentName="p">{`defaultOptions`}</inlineCode>{` object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const defaultOptions = {
  // set wsEndpoint to null
  wsEndpoint: process.env.VUE_APP_GRAPHQL_WS,

  // Use the form expected by github for authorisation
  getAuth: tokenName => \`Bearer \${tokenName}\`,
};
`}</code></pre>
    <h2 {...{
      "id": "fetch-data"
    }}>{`Fetch data`}</h2>
    <h3 {...{
      "id": "imports"
    }}>{`Imports`}</h3>
    <p>{`Add the following imports to the top of the script section of `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import gql from 'graphql-tag';
`}</code></pre>
    <h3 {...{
      "id": "query"
    }}>{`Query`}</h3>
    <p>{`Next we’ll assemble our GraphQL query to fetch only the data we need from the GraphQL API. We’ll do this using the `}<inlineCode parentName="p">{`gql`}</inlineCode>{` helper we just imported. The `}<inlineCode parentName="p">{`gql`}</inlineCode>{` helper lets you write GraphQL queries using interpolated strings (backticks) in JavaScript. In addition, we’ll be using the `}<inlineCode parentName="p">{`Query`}</inlineCode>{` component from `}<inlineCode parentName="p">{`vue-apollo`}</inlineCode>{` which gives us some great information about our query’s loading state in addition to the data.`}</p>
    <p>{`You can use GitHub’s `}<a parentName="p" {...{
        "href": "https://developer.github.com/v4/explorer/"
      }}>{`explorer`}</a>{` tool to write and test your own queries. Try copying the query below and experiment with changing the properties. You can also click the “Docs” button in the top right of the explorer to view all of the available data and query parameters.`}</p>
    <p>{`If you’d like some more information regarding writing queries and using the Query component, we recommend `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/tutorial/queries"
      }}>{`Apollo’s documentation`}</a>{` on this topic.`}</p>
    <p>{`Add this after your imports:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-1"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`const REPO_QUERY = gql\`
  query REPO_QUERY {
    # Let's use carbon as our organization
    organization(login: "carbon-design-system") {
      # We'll grab all the repositories in one go. To load more resources
      # continuously, see the advanced topics.
      repositories(first: 75, orderBy: { field: UPDATED_AT, direction: DESC }) {
        totalCount
        nodes {
          url
          homepageUrl
          issues(filterBy: { states: OPEN }) {
            totalCount
          }
          stargazers {
            totalCount
          }
          releases(first: 1) {
            totalCount
            nodes {
              name
            }
          }
          name
          updatedAt
          createdAt
          description
          id
        }
      }
    }
  }
\`;
`}</code></pre>
    <p>{`Next let’s we need to configure apollo in our component script, adding the following after the data() declaration.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-2"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`apollo: {
  organization: REPO_QUERY
},
`}</code></pre>
    <p>{`At this point, we should run our query view the raw the results to verify that the request is working.`}</p>
    <p>{`In RepoPage.vue add the following before the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` tag.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-3"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ this.organization }}
`}</code></pre>
    <p>{`When the data loads you should see the response rendered on your repository page. If not, check the console to see if there are any errors and fix.`}</p>
    <p>{`Revert this last change and continue.`}</p>
    <p>{`This data is not quite in the format our `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` component is expecting so we’ll use a computed property to transform it. Computed properties in Vue cache and watch their reactive dependencies for us.`}</p>
    <p>{`Remove the ‘rows’ constant and its use in the data declaration, and add this computed property.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-4"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`computed: {
  rows() {
      if (!this.organization) {
      return [];
    } else {
      return this.organization.repositories.nodes.map(row => ({
        ...row,
        key: row.id,
        stars: row.stargazers.totalCount,
        issueCount: row.issues.totalCount,
        createdAt: new Date(row.createdAt).toLocaleDateString(),
        updatedAt: new Date(row.updatedAt).toLocaleDateString(),
        links: { url: row.url, homepageUrl: row.homepageUrl }
      }));
    }
  }
}
`}</code></pre>
    <p>{`At this point you have a working table but the links column clearly isn’t what we want.`}</p>
    <h3 {...{
      "id": "helper-component"
    }}>{`Helper component`}</h3>
    <p>{`This column in the data table will be a list of repository and home page links, so let’s create a component called `}<inlineCode parentName="p">{`LinkList`}</inlineCode>{`.`}</p>
    <p>{`Add the following to create your component:`}</p>
    <p>{`A template section:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagelinklistvue"
    }}>{`src/views/RepoPage/LinkList.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<ul class="link-list">
  <li>
    <cv-link :href="url">GitHub</cv-link>
  </li>

  <li v-if="homepageUrl">
    <span>&nbsp;|&nbsp;</span>
    <cv-link :href="homepageUrl">Homepage</cv-link>
  </li>
</ul>
`}</code></pre>
    <p>{`A script section:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagelinklistvue-1"
    }}>{`src/views/RepoPage/LinkList.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`export default {
  name: 'LinkList',
  props: {
    url: String,
    homepageUrl: String,
  },
};
`}</code></pre>
    <p>{`And a style section:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagelinklistvue-2"
    }}>{`src/views/RepoPage/LinkList.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.link-list {
  display: flex;
}
`}</code></pre>
    <p>{`Now let’s make use of this component in our `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` component.`}</p>
    <p>{`At the top of the script section import the link list component:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import LinkList from './LinkList';
`}</code></pre>
    <p>{`And below the name of the component add:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-1"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  components: { LinkList },
`}</code></pre>
    <p>{`Then make use of it in our template replacing:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-2"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-data-table-cell
  v-for="(cell, cellIndex) in row.data"
  :key="\`\${cellIndex}\`"
  >{{cell}}</cv-data-table-cell>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`with`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-3"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-data-table-cell v-for="(cell, cellIndex) in row.data" :key="\`\${cellIndex}\`">
  <template v-if="!cell.url">
    {{cell}}
  </template>
  <link-list v-else :url="cell.url" :homepage-url="cell.homepageUrl" />
</cv-data-table-cell>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Here in order to switch between the standard rendering of a data cell we’ve wrapped our standard `}<inlineCode parentName="p">{`{{cell}}`}</inlineCode>{` rendering in a template tag. The template tag is non-rendering so it will dissapear, leaving us with the same content as before.`}</p>
    <p>{`Using the v-if and v-else directives we switch based on the contents of the cell between the standard rendering and the LinkList component.`}</p>
    <p>{`Checking our output again, you should now see the LinkList component rendering the final column.`}</p>
    <p>{`Next we’ll update our row description. Update the computed property data() in `}<inlineCode parentName="p">{`RepoTable.vue`}</inlineCode>{` to have the following description:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-4"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  description: row.description
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Check the output again and you should find the descriptions are updated.`}</p>
    <p>{`After this many refreshes you may have noticed a slight delay in the data loading. As outlined in the `}<a parentName="p" {...{
        "href": "https://vue-apollo.netlify.com/guide/apollo/#apollo"
      }}>{`documentation`}</a>{`, all components contained under one with an apolloProvider have a `}<inlineCode parentName="p">{`$apollo`}</inlineCode>{` attribute. As we added the `}<inlineCode parentName="p">{`apolloProvider`}</inlineCode>{` to our app when creating the Vue instance it is available to us everywhere.`}</p>
    <p>{`We can use the property to react to `}<a parentName="p" {...{
        "href": "https://vue-apollo.netlify.com/guide/apollo/queries.html#loading-state"
      }}>{`loading state`}</a>{`.`}</p>
    <p>{`First let’s demonstrate that this works.`}</p>
    <h4 {...{
      "id": "srcviewsrepopagerepopagevue-5"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h4>
    <p>{`Pass the loading state into our `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` component by updating the template with the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<repo-table
  :headers="headers"
  :rows="rows"
  title="Carbon Repositories"
  helperText="A collection of public Carbon repositories."
  :loading="$apollo.loading"
/>
`}</code></pre>
    <p>{`Next add this property to the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` component:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-5"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  props: {
    headers: Array,
    rows: Array,
    title: String,
    helperText: String,
    loading: Boolean,
  },
`}</code></pre>
    <p>{`Making use of the property to display a loading message.`}</p>
    <p>{`Replace:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-6"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-data-table
  :columns="columns"
  :title="title"
  :helper-text="helperText"
></cv-data-table>
`}</code></pre>
    <p>{`with:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-7"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div v-if="loading">Loading...</div>
<cv-data-table
  v-else
  :columns="columns"
  :title="title"
  :helper-text="helperText"
></cv-data-table>
`}</code></pre>
    <p>{`Here we have made use of the v-if and v-else directives to switch content based on the state of `}<inlineCode parentName="p">{`$apollo.loading`}</inlineCode>{`. If you refresh your app you should see this take effect.`}</p>
    <p>{`Now that we know this is works let’s try something a bit more sophisticated and replace the div containing our loading message with use of the `}<inlineCode parentName="p">{`CvDataTableSkeleton`}</inlineCode>{` component.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-8"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-data-table-skeleton
  v-if="loading"
  :columns="columns"
  :title="title"
  :helper-text="helperText"
  :rows="10"
/>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`We need to tell the loading skeleton how many rows to render, so let’s use 10 skeleton rows to prepare for the next enhancement…`}</p>
    <h2 {...{
      "id": "add-pagination"
    }}>{`Add pagination`}</h2>
    <p>{`Pagination! Instead of rendering every repository, let’s add pagination to the data table to only render 10 at a time. Depending on your specific requirements, you may need to fetch new data each time that you interact with the pagination component, but for simplicity, we’re going to make one request to fetch all data, and then paginate the in-memory row data.`}</p>
    <p>{`Let’s start by adjusting our `}<inlineCode parentName="p">{`PageTable`}</inlineCode>{` component template to add pagination. If you review the `}<a parentName="p" {...{
        "href": "http://vue.carbondesignsystem.com/?path=/info/components-cvdatatable--default"
      }}>{`storybook notes`}</a>{` you’ll see that pagination is added to the data table by supplying a pagination object and listening for pagination events as follows.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-9"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <cv-data-table
    v-else
    :columns="columns"
    :title="title"
    :helper-text="helperText"
    :pagination="{ numberOfItems: this.totalRows }"
    @pagination="$emit('pagination', $event)"
>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p><em parentName="p">{`Note: `}<inlineCode parentName="em">{`:prop`}</inlineCode>{` is an abbreviation of `}<inlineCode parentName="em">{`v-bind:prop`}</inlineCode></em>{`
`}<em parentName="p">{`Note: `}<inlineCode parentName="em">{`@event`}</inlineCode>{` is an abbreviation of `}<inlineCode parentName="em">{`v-on:event`}</inlineCode></em></p>
    <p>{`In the pagination event we’ve used $emit and $event to re-raise the pagination event to our `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{` component so that it can arrange to pass only the rows we want to see to the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` component.`}</p>
    <p>{`We also need to add the `}<inlineCode parentName="p">{`totalRows`}</inlineCode>{` property used in the data tables pagination property.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-10"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`totalRows: Number,
`}</code></pre>
    <p>{`Next to our `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{` component, let’s first update our template by updating our `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` use with the following attributes.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-6"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`:rows="pagedRows"
:totalRows="rows.length"
@pagination="onPagination"
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`replacing`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-7"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`:rows="rows"
`}</code></pre>
    <p>{`Next in the data property of our component add values for `}<inlineCode parentName="p">{`pageSize`}</inlineCode>{`, `}<inlineCode parentName="p">{`pageStart`}</inlineCode>{` and `}<inlineCode parentName="p">{`page`}</inlineCode>{` into the data method return to support our pagination.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-8"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  data() {
    return {
      headers,
      pageSize: 0,
      pageStart: 0,
      page: 0
    };
  },
`}</code></pre>
    <p><em parentName="p">{`Note: We could have passed values for `}<inlineCode parentName="em">{`pageSize`}</inlineCode>{` and `}<inlineCode parentName="em">{`page`}</inlineCode>{` into our pagination component if we had a specific page or page size we wanted to start with. Instead we are relying on the pagination component to set some sensible defaults and provide us with the details through an event.`}</em></p>
    <p>{`Then before we can see our paginated table we need to add: a `}<inlineCode parentName="p">{`pagedRows`}</inlineCode>{` computed property to select the slice of input rows we are interested in, and a method to handle the pagination event.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-9"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  computed: {
    // other computed properties
    // ...
    pagedRows() {
      return this.rows.slice(this.pageStart, this.pageStart + this.pageSize);
    }
  },
  methods: {
    onPagination(val) {
      this.pageSize = val.length;
      this.pageStart = val.start;
      this.page = val.page;
    }
  }
`}</code></pre>
    <p><em parentName="p">{`Note: Like the other Carbon Vue components,`}</em>{` `}<inlineCode parentName="p">{`Pagination`}</inlineCode>{` `}<em parentName="p">{`component examples can be found in `}<a parentName="em" {...{
          "href": "http://vue.carbondesignsystem.com/?path=/story/components-cvpagination--default"
        }}>{`Storybook`}</a>{` by browsing the story and knobs.`}</em></p>
    <p>{`That does it! Your data table should fetch GitHub data on first render. You can expand each row to see the repository’s description. You can modify the pagination items per page and cycle through pages or jump to a specific page of repositories.`}</p>
    <h2 {...{
      "id": "mystery"
    }}>{`Mystery`}</h2>
    <p>{`Hmmm, there is at least one more issue to resolve. If you expand a row or two to see the repository descriptions you will and then change page. What happens?`}</p>
    <p>{`Assuming you didn’t catch this earlier you will find that the expanded rows, stay expanded after paging. That is if row two was expanded before pagination it is expanded after.`}</p>
    <p>{`This is because we chose poor values to use as our row and cell keys as we iterated over them. The result is that Vue sees these items as having the same key and makes the assumption that content but not state has changed.`}</p>
    <p>{`To fix this add the following to the RepoPage component you should be able to find something better.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  watch: {
    rows() {
      if (this.organization) {
        console.dir(this.organization.repositories.nodes);
      }
    }
  },
`}</code></pre>
    <p><em parentName="p">{`Hint: `}<inlineCode parentName="em">{`id`}</inlineCode>{` and `}<inlineCode parentName="em">{`url`}</inlineCode>{` are likely unique properties, you could use either of these to update the prototype. In fact we already pass the id value to RepoTable.`}</em></p>
    <p>{`Can you fix it?`}</p>
    <h2 {...{
      "id": "submit-pull-request"
    }}>{`Submit pull request`}</h2>
    <p>{`We’re going to submit a pull request to verify completion of this tutorial step.`}</p>
    <h3 {...{
      "id": "continuous-integration-ci-check"
    }}>{`Continuous integration (CI) check`}</h3>
    <p>{`Run the CI check to make sure we’re all set to submit a pull request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn ci-check
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues running the CI check? `}<a parentName="em" {...{
          "href": "/tutorial/vue/step-1#continuous-integration-ci-check"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "git-commit-and-push"
    }}>{`Git commit and push`}</h3>
    <p>{`Before we can create a pull request, stage and commit all of your changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git add --all && git commit -m "feat(tutorial): complete step 3"
`}</code></pre>
    <p>{`Then, push to your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git push origin vue-step-3
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues pushing your changes? `}<a parentName="em" {...{
          "href": "/tutorial/vue/step-1#git-commit-and-push"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "pull-request-pr"
    }}>{`Pull request (PR)`}</h3>
    <p>{`Finally, visit `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
      }}>{`carbon-tutorial-vue`}</a>{` to “Compare & pull request”. In doing so, make sure that you are comparing to `}<inlineCode parentName="p">{`vue-step-3`}</inlineCode>{` into `}<inlineCode parentName="p">{`base: vue-step-3`}</inlineCode>{`.`}</p>
    <p><em parentName="p">{`Note: Expect your tutorial step PRs to be reviewed by the Carbon team but not merged. We’ll close your PR so we can keep the repository’s remote branches pristine and ready for the next person!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      